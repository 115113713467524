<template>
  <b-container class="mt-2 mb-2">
    <b-card>
      <h1>Privacy Policy</h1>
      <p>Last updated: January 27, 2023</p>
      <br />
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy. This Privacy Policy has been
        created with the help of the Free Privacy Policy Generator.
      </p>
      <br />
      <h2>Interpretation and Definitions</h2>
      <br />
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <br />
      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">Account </span>
        <span class="p"
          >means a unique account created for You to access our Service or parts
          of our Service.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">Affiliate </span>
        <span class="p"
          >means an entity that controls, is controlled by or is under common
          control with a party, where &quot;control&quot; means ownership of 50%
          or more of the shares, equity interest or other securities entitled to
          vote for election of directors or other managing authority.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">Application </span>
        <span class="p"
          >refers to Affald.online, the software program provided by the
          Company. </span
        >Company
        <span class="p"
          >(referred to as either &quot;the Company&quot;, &quot;We&quot;,
          &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to
          Rezycl.com, Håndværkervej 1b.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 13pt;
          text-align: left;
        "
      >
        <span class="font-weight-bold">Country </span>
        <span class="p">refers to: Denmark</span>
      </p>
      <p
        style="
          padding-top: 1pt;
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">Device </span>
        <span class="p"
          >means any device that can access the Service such as a computer, a
          cellphone or a digital tablet.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 113%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">Personal Data </span>
        <span class="p"
          >is any information that relates to an identified or identifiable
          individual.</span
        >
      </p>
      <p style="padding-left: 42pt; text-indent: 0pt; text-align: left">
        <span class="font-weight-bold">Service </span>
        <span class="p">refers to the Application.</span>
      </p>
      <p
        style="
          padding-top: 1pt;
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">Service Provider </span>
        <span class="p"
          >means any natural or legal person who processes the data on behalf of
          the Company. It refers to third-party companies or individuals
          employed by the Company to facilitate the Service, to provide the
          Service on behalf of the Company, to perform services related to the
          Service or to assist the Company in analyzing how the Service is
          used.</span
        >
      </p>
      <p
        style="
          padding-top: 3pt;
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">Usage Data </span>
        <span class="p"
          >refers to data collected automatically, either generated by the use
          of the Service or from the Service infrastructure itself (for example,
          the duration of a page visit).</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: justify;
        "
      >
        <span class="font-weight-bold">You </span>
        <span class="p"
          >means the individual accessing or using the Service, or the company,
          or other legal entity on behalf of which such individual is accessing
          or using the Service, as applicable.</span
        >
      </p>
      <p
        class="s1"
        style="
          padding-top: 10pt;
          padding-bottom: 3pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Collecting and Using Your Personal Data
      </p>
      <p
        style="
          padding-left: 5pt;
          text-indent: 0pt;
          line-height: 1pt;
          text-align: left;
        "
      />
      <h3
        style="
          padding-top: 4pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Types of Data Collected
      </h3>
      <p
        class="s2 font-weight-bold"
        style="
          padding-top: 12pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Personal Data
      </p>
      <p
        style="
          padding-top: 1pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: justify;
        "
      >
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 42pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Email address
      </p>
      <p
        style="
          padding-top: 1pt;
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        First name and last name Phone number
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 13pt;
          text-align: left;
        "
      >
        Usage Data
      </p>
      <br />
      <p
        class="s2 font-weight-bold"
        style="padding-left: 6pt; text-indent: 0pt; text-align: left"
      >
        Usage Data
      </p>
      <p
        style="
          padding-top: 1pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: justify;
        "
      >
        Usage Data is collected automatically when using the Service.
      </p>
      <p
        style="
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        Usage Data may include information such as Your Device&#39;s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p
        style="
          padding-top: 9pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <br />
      <p
        class="s2 font-weight-bold"
        style="
          padding-top: 9pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Information Collected while Using the Application
      </p>
      <p
        style="
          padding-top: 2pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
      </p>
      <ul id="l1">
        <li data-list-text="">
          <p
            style="
              padding-top: 9pt;
              padding-left: 24pt;
              text-indent: -18pt;
              text-align: left;
            "
          >
            Information regarding your location
          </p>
        </li>
      </ul>
      <p
        style="
          padding-top: 3pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company&#39;s servers and/or a Service Provider&#39;s server or it may
        be simply stored on Your device.
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </p>
      <h3
        style="
          padding-top: 9pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Use of Your Personal Data
      </h3>
      <p
        style="
          padding-top: 2pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        The Company may use Personal Data for the following purposes:
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold"
          >To provide and maintain our Service </span
        ><span class="p">, including to monitor the usage of our Service.</span>
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">To manage Your Account: </span>
        <span class="p"
          >to manage Your registration as a user of the Service. The Personal
          Data You provide can give You access to different functionalities of
          the Service that are available to You as a registered user.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 113%;
          text-align: left;
        "
      >
        <span class="font-weight-bold"
          >For the performance of a contract:
        </span>
        <span class="p"
          >the development, compliance and undertaking of the purchase contract
          for the products, items or services You have purchased or of any other
          contract with Us through the Service.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">To contact You: </span>
        <span class="p"
          >To contact You by email, telephone calls, SMS, or other equivalent
          forms of electronic communication, such as a mobile application&#39;s
          push notifications regarding updates or informative communications
          related to the functionalities, products or contracted services,
          including the security updates, when necessary or reasonable for their
          implementation.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">To provide You </span>
        <span class="p"
          >with news, special offers and general information about other goods,
          services and events which we offer that are similar to those that you
          have already purchased or enquired about unless You have opted not to
          receive such information.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 13pt;
          text-align: left;
        "
      >
        <span class="font-weight-bold">To manage Your requests: </span>
        <span class="p">To attend and manage Your requests to Us.</span>
      </p>
      <p
        style="
          padding-top: 2pt;
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">For business transfers: </span>
        <span class="p"
          >We may use Your information to evaluate or conduct a merger,
          divestiture, restructuring, reorganization, dissolution, or other sale
          or transfer of some or all of Our assets, whether as a going concern
          or as part of bankruptcy, liquidation, or similar proceeding, in which
          Personal Data held by Us about our Service users is among the assets
          transferred.</span
        >
      </p>
      <p
        style="
          padding-left: 42pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        <span class="font-weight-bold">For other purposes </span
        ><span class="p"
          >: We may use Your information for other purposes, such as data
          analysis, identifying usage trends, determining the effectiveness of
          our promotional campaigns and to evaluate and improve our Service,
          products, services, marketing and your experience.</span
        >
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        We may share Your personal information in the following situations:
      </p>
      <ul>
        <li data-list-text="">
          <p
            style="
              padding-left: 24pt;
              text-indent: -18pt;
              line-height: 115%;
              text-align: left;
            "
          >
            <span class="font-weight-bold">With Service Providers: </span>
            <span class="p"
              >We may share Your personal information with Service Providers to
              monitor and analyze the use of our Service, to contact You.</span
            >
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-left: 24pt;
              text-indent: -18pt;
              line-height: 115%;
              text-align: left;
            "
          >
            <span class="font-weight-bold">For business transfers: </span>
            <span class="p"
              >We may share or transfer Your personal information in connection
              with, or during negotiations of, any merger, sale of Company
              assets, financing, or acquisition of all or a portion of Our
              business to another company.</span
            >
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-top: 3pt;
              padding-left: 24pt;
              text-indent: -18pt;
              line-height: 114%;
              text-align: left;
            "
          >
            <span class="font-weight-bold">With Affiliates: </span>
            <span class="p"
              >We may share Your information with Our affiliates, in which case
              we will require those affiliates to honor this Privacy Policy.
              Affiliates include Our parent company and any other subsidiaries,
              joint venture partners or other companies that We control or that
              are under common control with Us.</span
            >
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-left: 24pt;
              text-indent: -18pt;
              line-height: 114%;
              text-align: left;
            "
          >
            <span class="font-weight-bold">With business partners: </span>
            <span class="p"
              >We may share Your information with Our business partners to offer
              You certain products, services or promotions.</span
            >
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-left: 24pt;
              text-indent: -18pt;
              line-height: 114%;
              text-align: left;
            "
          >
            <span class="font-weight-bold"> With other users: </span>
            <span class="p"
              >when You share personal information or otherwise interact in the
              public areas with other users, such information may be viewed by
              all users and may be publicly distributed outside.</span
            >
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-left: 24pt;
              text-indent: -18pt;
              line-height: 114%;
              text-align: left;
            "
          >
            <span class="font-weight-bold">With Your consent </span
            ><span class="p"
              >: We may disclose Your personal information for any other purpose
              with Your consent.</span
            >
          </p>
        </li>
      </ul>
      <br />
      <h3
        style="
          padding-top: 9pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Retention of Your Personal Data
      </h3>
      <p
        style="
          padding-top: 2pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>
      <p
        style="
          padding-top: 9pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <h3
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Transfer of Your Personal Data
      </h3>
      <p
        style="
          padding-top: 2pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        Your information, including Personal Data, is processed at the
        Company&#39;s operating offices and in any other places where the
        parties involved in the processing are located. It means that this
        information may be transferred to — and maintained on — computers
        located outside of Your state, province, country or other governmental
        jurisdiction where the data protection laws may differ than those from
        Your jurisdiction.
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 113%;
          text-align: left;
        "
      >
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>
      <h3
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Delete Your Personal Data
      </h3>
      <p
        style="
          padding-top: 2pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about You.
      </p>
      <p
        style="
          padding-top: 9pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        Our Service may give You the ability to delete certain information about
        You from within the Service.
      </p>
      <p
        style="
          padding-top: 3pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        You may update, amend, or delete Your information at any time by signing
        in to Your Account, if you have one, and visiting the account settings
        section that allows you to manage Your personal information. You may
        also contact Us to request access to, correct, or delete any personal
        information that You have provided to Us.
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        Please note, however, that We may need to retain certain information
        when we have a legal obligation or lawful basis to do so.
      </p>
      <h3
        style="
          padding-top: 9pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Disclosure of Your Personal Data
      </h3>
      <p
        class="s2 font-weight-bold"
        style="
          padding-top: 12pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Business Transactions
      </p>
      <p
        style="
          padding-top: 1pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <p
        class="s2 font-weight-bold"
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Law enforcement
      </p>
      <p
        style="
          padding-top: 2pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 113%;
          text-align: justify;
        "
      >
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <p
        class="s2 font-weight-bold"
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Other legal requirements
      </p>
      <p
        style="
          padding-top: 1pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul>
        <li data-list-text="">
          <p
            style="
              padding-top: 9pt;
              padding-left: 24pt;
              text-indent: -18pt;
              text-align: left;
            "
          >
            Comply with a legal obligation
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-top: 1pt;
              padding-left: 24pt;
              text-indent: -18pt;
              text-align: left;
            "
          >
            Protect and defend the rights or property of the Company
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-top: 2pt;
              padding-left: 24pt;
              text-indent: -18pt;
              text-align: left;
            "
          >
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-top: 1pt;
              padding-left: 24pt;
              text-indent: -18pt;
              text-align: left;
            "
          >
            Protect the personal safety of Users of the Service or the public
          </p>
        </li>
        <li data-list-text="">
          <p
            style="
              padding-top: 1pt;
              padding-left: 24pt;
              text-indent: -18pt;
              text-align: left;
            "
          >
            Protect against legal liability
          </p>
        </li>
      </ul>
      <br />
      <p
        class="font-weight-bold"
        style="padding-left: 6pt; text-indent: 0pt; text-align: left"
      >
        Security of Your Personal Data
      </p>
      <p
        style="
          padding-top: 2pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <h3
        class="s1"
        style="
          padding-top: 10pt;
          padding-bottom: 3pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: justify;
        "
      >
        Children&#39;s Privacy
      </h3>
      <p
        style="
          padding-top: 5pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </p>
      <p
        style="
          padding-top: 3pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent&#39;s consent before We collect and use that
        information.
      </p>
      <h3
        class="s1"
        style="
          padding-top: 10pt;
          padding-bottom: 3pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Links to Other Websites
      </h3>
      <p
        style="
          padding-top: 5pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 113%;
          text-align: left;
        "
      >
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party&#39;s site. We strongly advise You to review the Privacy
        Policy of every site You visit.
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 113%;
          text-align: left;
        "
      >
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h3
        class="s1"
        style="
          padding-top: 10pt;
          padding-bottom: 3pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Changes to this Privacy Policy
      </h3>
      <p
        style="
          padding-top: 5pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 113%;
          text-align: left;
        "
      >
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last
        updated&quot; date at the top of this Privacy Policy.
      </p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 6pt;
          text-indent: 0pt;
          line-height: 114%;
          text-align: left;
        "
      >
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h3
        class="s1"
        style="
          padding-top: 10pt;
          padding-bottom: 3pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        Contact Us
      </h3>
      <p
        style="
          padding-top: 5pt;
          padding-left: 6pt;
          text-indent: 0pt;
          text-align: left;
        "
      >
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul>
        <li data-list-text="">
          <p style="padding-left: 24pt; text-indent: -18pt; text-align: left">
            By email: <a href="mailto:info@rezycl.com" target="_blank"
              >info@rezycl.com</a
            >
          </p>
        </li>
      </ul>
    </b-card>
  </b-container>
</template>

<script>
import { BContainer, BCard } from "bootstrap-vue";
export default {
  components: {
    BContainer,
    BCard,
  },
};
</script>